import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Container,
    Row,
    Col,
    Button, 
    Spinner,
    Navbar,
    NavbarBrand
} from 'reactstrap';
import * as _ from 'lodash';
import { setMenuLevel, toggleLeftNav } from '../../redux/actions/menu';
import logo from '../../assets/images/logo.png';
import { useMediaQuery } from 'react-responsive';


import './Home.scss';

function Home() {
    const menuLevel = useSelector((s) => s.menuReducer.menuLevel);
    const menuPending = useSelector((s) => s.menuReducer.menuPending);
    const dispatch = useDispatch();
    const handleMenuChange = (parentId) => {
        dispatch(
            setMenuLevel({
                menuLevel: menuLevel + 1,
                parentId
            })
        );
        dispatch(toggleLeftNav());
    }

    const toggle = () => {
        dispatch(toggleLeftNav());
    }

    const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });

    const menuItems = useSelector((s) => s.menuReducer.menuItems);
    const options = _.map(menuItems, (item) => {
        return (
            <Col key={_.get(item, 'ID')} className="menu-option" xs="6" lg="3">
                <Button className="icon-btn" onClick={() => handleMenuChange(_.get(item, 'ID'))}>
                    <FontAwesomeIcon className="menu-option-icon" icon={['fal', _.get(item, 'icon')]} size="2x" fixedWidth />
                    <span className="menu-option-title">{ _.get(item, 'title') }</span>
                    <p>{ _.get(item, 'subtitle') }</p>
                </Button>
            </Col>
        )
    });

    return (
        <div className="home h-100">
            {
                menuPending ? 
                <div className="d-flex my-5">
                    <Spinner style={{ width: '3rem', height: '3rem' }}/>
                </div>:
                <Container className="home-container">
                        <Navbar dark>
                            {
                                !isLargeScreen &&
                                <Button className="hidden-lg icon-btn icon-white" onClick={toggle}>
                                    <FontAwesomeIcon icon="bars" size="lg" />
                                </Button>
                            }
                            <NavbarBrand id="nav-header" style={{ margin: 'auto' }}>COMFORT Communication App</NavbarBrand>
                        </Navbar>
                    <Row className="home-menu">
                        {options}
                    </Row>
                    <div className="home-footer">
                        <img src={logo} alt="Communicate Comfort Logo" />
                    </div>
                </Container>
            }
        </div>
    );
}

export default Home;
