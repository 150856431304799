import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import './App.scss';

import Header from '../components/Header';
import LeftNav from '../components/LeftNav';
import NewVisitorForm from '../components/NewVisitorForm';

import Home from './home/Home';
import Content from './content/Content';

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
library.add(fas, fal);


function App() {

  const visitorId = useSelector((s) => s.userReducer.visitorId);

  return (
    <div className="App h-100">
      <NewVisitorForm display={!visitorId} />
      <Router>
        <Container className="wrapper h-100" fluid>
          <Row className="h-100" noGutters>
            <LeftNav />
            <Col xs="12" lg="9" className="h-100 d-flex flex-column">
              <div className="d-none">
                <Header />
              </div>
              <Switch>
                <Route path={[
                  "/:levelOne/:levelTwo/:levelThree/:item",
                  "/:levelOne/:levelTwo/:levelThree",
                  "/:levelOne/:levelTwo/"
                  ]}>
                  <Content />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
            </Col>
          </Row>
        </Container>
      </Router>
    </div>
  );
}

export default App;
