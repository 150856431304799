import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMenu, toggleLeftNav } from '../redux/actions/menu';
import { Navbar, Button, NavbarBrand } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';

import './Header.scss';

export default function Header(sectionTitle) {

    const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });

    const dispatch = useDispatch(); 
    useEffect(() => {
        dispatch(getMenu());
    }, [dispatch]);

    const toggle = () => {
        dispatch(toggleLeftNav());
    }

    return (
        <Navbar dark>
            {
                !isLargeScreen &&
                <Button className="hidden-lg icon-btn icon-white" onClick={toggle}>
                    <FontAwesomeIcon icon="bars" size="lg" />
                </Button>
            }{
                (sectionTitle.sectionTitle) &&
                <NavbarBrand id="nav-header" style={{ margin: 'auto' }}>{sectionTitle.sectionTitle}</NavbarBrand>
            }{
                (!sectionTitle.sectionTitle) &&
                <NavbarBrand id="nav-header" style={{ margin: 'auto' }}>COMFORT Communication App</NavbarBrand>
            }
        </Navbar>
    )
}