import { useEffect, useRef, useState } from 'react';

export function useDebounce (value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const didMount = useRef(false);

  useEffect(
    () => {
      if (didMount.current) {
        const handler = setTimeout(() => {
          setDebouncedValue(value || null);
        }, delay);

        // Cancel the timeout if value changes (also on delay change or unmount)
        return () => {
          clearTimeout(handler);
        };
      } else {
        didMount.current = true;
      }
    },
    [value, delay, didMount],
  );

  return debouncedValue;
}
