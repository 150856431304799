export const GET_MENU = 'MENU/GET_MENU';
export const GET_MENU_SUCCESS = 'MENU/GET_MENU_SUCCESS';
export const GET_MENU_ERRORS = 'MENU/GET_MENU_ERRORS';
export const SET_MENU_LEVEL = 'MENU/SET_MENU_LEVEL';
export const TOGGLE_LEFT_NAV = 'MENU/TOGGLE_LEFT_NAV';

export const toggleLeftNav = () => {
    return {
        type: TOGGLE_LEFT_NAV,
    };
}

export const getMenu = () => {
    return {
      type: GET_MENU,
    };
};

export const setMenuLevel = (params) => {
    return {
        type: SET_MENU_LEVEL,
        params,
    };
}