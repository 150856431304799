import * as Actions from '../actions/user';
import { GET_SEARCH } from '../actions/search';

export const initialState = {
    visitorId: false,
    recentSearches: []
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SUBMIT_USER_FORM_SUCCESS:
            return {
            ...state,
            visitorId: action.response.data,
        };
        case GET_SEARCH:
            const copy = state.recentSearches ? state.recentSearches : [];
            //Prevent duplicate recent searches
            const newRecentSearches = copy.filter(item => item !== action.searchTerm);
            newRecentSearches.unshift(action.searchTerm);
            //Limit recent searches to 10
            if(newRecentSearches.length > 10) newRecentSearches.length = 10;
            return {
                ...state,
                recentSearches: newRecentSearches
            };
      default:
        return state;
    }
};

export default userReducer;
