export const GET_SEARCH = 'SEARCH/GET_SEARCH';
export const GET_SEARCH_SUCCESS = 'SEARCH/GET_SEARCH_SUCCESS';
export const GET_SEARCH_ERRORS = 'SEARCH/GET_SEARCH_ERRORS';
export const CLEAR_SEARCH = 'SEARCH/CLEAR_SEARCH';

export const getSearch = (searchTerm) => {
    return {
      type: GET_SEARCH,
      searchTerm,
    };
};

export const clearSearch = () => {
  return {
    type: CLEAR_SEARCH
  }
}
