import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
    Modal,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Container,
} from 'reactstrap';
import { submitUserForm } from '../redux/actions/user';
import logo from '../assets/images/logo.png';

export default function NewVisitorForm({display}) {
    const dispatch = useDispatch();
    const {register, handleSubmit} = useForm();
    const onSubmit = (vals) => {
        dispatch(submitUserForm(vals));
    };
    return (
        <Modal isOpen={display}>
            <Container>
                <Form onSubmit={handleSubmit(onSubmit)} className="text-center">
                    <span className="text-center form-welcome"><b>Welcome!</b></span>
                    <p className="text-center">
                        Welcome to the <b>Comfort Communication App!</b>
                    </p>
                    <p className="text-center">
                        We ask that you please fill out the information below before using this free resource.
                    </p>
                    <p className="text-center">
                        Within the app, click on each icon to learn more about communication practices and strategies for improving your communication. To learn more visit <a className="welcome-link" href="http://communicatecomfort.com">communicatecomfort.com</a>.
                    </p>
                    <FormGroup className="text-center">
                        <Label for="whoAreYou" className="modal-label">Who are you?</Label>
                        <Input innerRef={register} type="select" name="visitor_type" id="whoAreYou" className="modal-select">
                            <option value="student">Student</option>
                            <option value="provider">Provider/Clinician</option>
                            <option value="faculty">Faculty</option>
                            <option value="other">Other</option>
                        </Input>
                    </FormGroup>
                    <FormGroup className="text-center">
                        <Label for="whereDidYouHear" className="modal-label">Where did you hear about Communicate Comfort?</Label>
                        <Input innerRef={register} type="select" name="visitor_source" id="whereDidYouHear" className="modal-select">
                            <option value="teacher">Teacher</option>
                            <option value="organization">Professional Organization</option>
                            <option value="colleague">Colleague</option>
                            <option value="internet">Internet</option>
                        </Input>
                    </FormGroup>
                    <Button className="modal-btn">Submit</Button>
                    <div className="modal-footer">
                        <img src={logo} alt="Communicate Comfort Logo" />
                    </div>
                </Form>
            </Container>
        </Modal>
    )
}   