import * as Actions from '../actions/content';

export const initialState = {
    contentPending: false,
    contentType: null,
    contentData: [],
    contentErrors: null,
};

const contentReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_CONTENT:
            return {
            ...state,
            contentData: [],
            contentPending: true,
        };
        case Actions.GET_CONTENT_SUCCESS:
            return {
            ...state,
            contentPending: false,
            contentData: action.response.data,
        };
        case Actions.GET_CONTENT_ERRORS:
            return {
            ...state,
            contentPending: false,
            contentErrors: action.error,
        };
      default:
        return state;
    }
};

export default contentReducer;
