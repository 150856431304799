import {
    put,
    call,
    takeLatest,
} from 'redux-saga/effects';
import axios from "axios";
import * as searchActions from '../actions/search';

function* doGetSearch (action) {
    try {
        const {searchTerm} = action;
        const req = new URLSearchParams({s: searchTerm}).toString();
        const response = yield call(
            axios.get, 
            `http://app.communicatecomfort.com/wp-json/hc/v1/search?${req}`
        );
        yield put({
            type: searchActions.GET_SEARCH_SUCCESS,
            response
        })
      } catch(err) {
        console.error(err)
      }
}

export default function* searchSagas() {
    yield takeLatest(searchActions.GET_SEARCH, doGetSearch);
}