import React, {useState, useEffect, useRef, useCallback} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';
import * as _ from 'lodash';

import './Card.scss';

export default function ResponseCard({card, active, cardClasses, deckTitle}){
    const [showPreview, setShowPreview] = useState(false)
    const [showResponses, setShowResponses] = useState(false);
    const wrapperRef = useRef(null);
    const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });

    const showResponseText = isLargeScreen ? 
        'Click the card to view potential responses' : 
        'Tap to view potential responses';
    
    const hideResponseText = isLargeScreen ? 
        'Click the card to hide potential responses' : 
        'Tap to hide potential responses';
    
    const showConversationText = isLargeScreen ?
        'Click the card to review conversation starters' :
        'Tap to review conversation starters';

    const hideConversationText = isLargeScreen ?
        'Click the card to hide conversation starters' :
        'Tap to hide conversation starters';

    useEffect(() => {
        document.addEventListener('click', useInsideClickListener);

        return () => {
        document.removeEventListener('click', useInsideClickListener);
        };
    });

    useEffect(() => {
        if(active){
            setShowPreview(true);
        } else {
            setShowPreview(false)
        }
        return () => setShowResponses(false);
    }, [active]);

    const useInsideClickListener = useCallback((event) => {
        if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
            setShowResponses(!showResponses);
        }
    }, [wrapperRef, setShowResponses, showResponses]);

    const responses = _.map(_.get(card, 'card_responses', []), (response, idx) => {
        return(
            <div className="response text-center" key={`response-${idx}`}>
                {
                    (deckTitle != 'View All - Family Caregivers, Assessing the Caregiver') &&
                    <h5 className="response-heading">{`Response ${idx + 1}`}</h5>
                }
                <p className="response-body">{response.response}</p>
            </div>
        )
    })

    const responseSide = 
        <div className={`${showPreview ? 'preview' : ''} ${showResponses ? 'show' : ''} response back ` + cardClasses}>
            <div className="help-text show-response-prompt">
                <FontAwesomeIcon icon="chevron-up" />
                <p>{deckTitle === 'Conversation Starters' ? showConversationText : showResponseText}</p>
            </div>
            <div className="text-center hide-response-prompt">
                <p>{deckTitle === 'Conversation Starters' ? hideConversationText : hideResponseText}</p>
                <FontAwesomeIcon icon="chevron-down" />
            </div>
            <div className="responses">{responses}</div>
        </div>

    const promptSide =
        <div className={"response front " + cardClasses}>
            <h4 className="card-title">{card.card_title}</h4>
            <p>{card.card_description}</p>
        </div>

    return (
        <div className="response-card-wrapper h-100" ref={wrapperRef}>
            {promptSide}
            {responseSide}
        </div>
    )
}