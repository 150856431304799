import {
    put,
    call,
    takeLatest,
} from 'redux-saga/effects';
import axios from "axios";
import * as userActions from '../actions/user';

function* doSubmitUserForm (action) {
    try {
        const {params: {visitor_type, visitor_source}} = action;
        const response = yield call(
            axios.post, 
            `http://app.communicatecomfort.com/wp-json/hc/v1/user`,
            {
                visitor_type,
                visitor_source
            }
        );
        yield put({
            type: userActions.SUBMIT_USER_FORM_SUCCESS,
            response
        })
      } catch(err) {
        console.error(err)
      }
}

export default function* searchSagas() {
    yield takeLatest(userActions.SUBMIT_USER_FORM, doSubmitUserForm);
}