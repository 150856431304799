import {
    put,
    call,
    takeLatest,
} from 'redux-saga/effects';
import axios from "axios";
import * as contentActions from '../actions/content';

function* doGetContent (action) {
    try {
        const {params: {levelOne, levelTwo, levelThree}} = action;
        let req = `http://app.communicatecomfort.com/wp-json/hc/v1/content?l1=${levelOne}&l2=${levelTwo}`
        if (!!levelThree) req += `&l3=${levelThree}`;
        const response = yield call(axios.get, req);
        yield put({
            type: contentActions.GET_CONTENT_SUCCESS,
            response
        })
      } catch(err) {
        console.error(err)
      }
}

export default function* contentSagas() {
    yield takeLatest(contentActions.GET_CONTENT, doGetContent);
}