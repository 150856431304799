import {
    put,
    call,
    takeLatest,
} from 'redux-saga/effects';
import axios from "axios";
import * as menuActions from '../actions/menu';

function* doGetMenu () {
    try {
        const response = yield call(axios.get, 'http://app.communicatecomfort.com/wp-json/hc/v1/menu');
        yield put({
            type: menuActions.GET_MENU_SUCCESS,
            response
        })
      } catch(err) {
        console.error(err)
      }
}

export default function* menuSagas() {
    yield takeLatest(menuActions.GET_MENU, doGetMenu);
}