import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Table as BootstrapTable, CustomInput } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import Header from '../components/Header';
import * as _ from 'lodash';

import './Table.scss';

function Table() {
    const [isSpanish, setIsSpanish] = useState(false);
    const tableData = useSelector((s) => _.get(s, 'contentReducer.contentData.post_content.table_rows', []));
    const table = {
        head: [],
        body: []
    };

    _.each(tableData, (row, idx) => {
        const cell1Val = isSpanish && !!row.row_include_spanish ? 
            row.row_cell_1_value_spanish : row.row_cell_1_value_english;
        const cell2Val = isSpanish && !!row.row_include_spanish ? 
            row.row_cell_2_value_spanish : row.row_cell_2_value_english;

        if(!!row.row_is_header){
            table.head.push (
                <tr key={idx}>
                    <th>{ReactHtmlParser(cell1Val)}</th>
                    <th>{ReactHtmlParser(cell2Val)}</th>
                </tr>
            )
        } else {
            table.body.push(
                <tr key={idx}>
                    <td>{ReactHtmlParser(cell1Val)}</td>
                    <td>{ReactHtmlParser(cell2Val)}</td>
                </tr>
            )
        }
    });

    const toggleSpanish = () => {
        setIsSpanish(!isSpanish);
    }

    return (
        <div className="hc-table">
            <Header />
            <div className="table-controls-wrapper text-right">
                <CustomInput
                    type="switch"
                    id="spanish-toggle"
                    name="spanishToggle"
                    label="Espanòl"
                    value={isSpanish}
                    onChange={toggleSpanish}
                />
            </div>
            <BootstrapTable striped>
                <thead>
                    {table.head}
                </thead>
                <tbody>
                    {table.body}
                </tbody>
            </BootstrapTable>
        </div>
    );
}

export default Table;
