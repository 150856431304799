import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    NavItem,
    Nav,
    Col,
    Container,
    Row,
    Spinner
} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import Search from './Search';
import { useDebounce } from '../hooks/useDebounce';
import { setMenuLevel, toggleLeftNav } from '../redux/actions/menu';
import { getSearch, clearSearch } from '../redux/actions/search';
import { useMediaQuery } from 'react-responsive'
import * as _ from 'lodash';

import './LeftNav.scss';

import logo from '../assets/images/logo.png';

export default function LeftNav() {

    const dispatch = useDispatch();

    const [userIsSearching, setUserIsSearching] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearch = useDebounce(searchValue, 600);

    const leftNavVisible = useSelector((s) => s.menuReducer.leftNavVisible);
    const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });

    const menuPending = useSelector((s) => s.menuReducer.menuPending);
    const menuLevel = useSelector((s) => s.menuReducer.menuLevel);
    const menuParent = useSelector((s) => s.menuReducer.menuParent);
    const menuTree = useSelector((s) => s.menuReducer.menuItems);
    const parentIndex = menuParent ? _.findIndex(menuTree, ['ID', menuParent]) : null;
    const itemsToRender = menuParent ? menuTree[parentIndex].children : menuTree; 

    const recentSearches = useSelector((s) => _.get(s, 'userReducer.recentSearches', []))
    const searchPending = useSelector((s) => _.get(s, 'searchReducer.searchPending', false));
    const searchedTerm = useSelector((s) => _.get(s, 'searchReducer.searchTerm'));
    const searchResults = useSelector((s) => _.get(s, 'searchReducer.searchResults', []));

    const handleSearchStart = () => {
        setUserIsSearching(true)
    }
    
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    }

    const handleDrilldown = (parentId) => {
        dispatch(
            setMenuLevel({
                menuLevel: menuLevel + 1,
                parentId
            })
        );
    }

    const menuBack = () => {
        dispatch(
            setMenuLevel({
                menuLevel: 0,
                parentId: null,
            })
        );
        dispatch(clearSearch());
        setSearchValue('');
        setUserIsSearching(false);
    }

    
    const toggle = () => {
        dispatch(toggleLeftNav());
    }
    
    const closeMenu = () => {
        toggle();
    }

    useEffect(
        () => {
          if (debouncedSearch) {
            dispatch(getSearch(debouncedSearch));
          }
        },
        [debouncedSearch, dispatch],
    );

    const renderedSearchResults = _.map(searchResults, (result, idx) => {
        return (
            <NavLink
                key={idx}
                className="item-link"
                to={result.path}
                onClick={closeMenu}
            >
                {ReactHtmlParser(result.title_markup)}
                <FontAwesomeIcon icon="chevron-right" />
            </NavLink>
        )
    });

    
    const searchSuggestions = _.map(recentSearches, (recentSearch, idx) => {
        return (
            <li key={idx} className="suggestion">
            <Button className="transparent-btn" onClick={() => setSearchValue(recentSearch)}>
                {recentSearch}
            </Button>
            </li>
        )
    });

    const rootMenu = () => {
        const render = _.map(itemsToRender, (item) => {
            return (
                <NavItem className="root-nav-item" key={item.ID}>
                    <Button 
                        className="transparent-btn"
                        onClick={() => handleDrilldown(item.ID)}
                    >
                        <FontAwesomeIcon className="nav-icon" icon={item.icon} size="lg" fixedWidth />
                        {item.title}
                    </Button>
                </NavItem>
            );
        });
        return render;
    }

    const subMenu = () => {
        const render = _.map(itemsToRender, (item) => {
            if(item.is_post || item.is_custom){
                return <ItemLink key={item.ID} item={item}/>;
            }
            if(item.is_term){
                return (
                    <div className="submenu-group" key={item.ID}>
                        <span className="item-name">{item.title}</span>
                        { item.description && <p>{item.description}</p> }
                        {
                            _.map(item.children, (item) => {
                                return <ItemLink key={item.ID} item={item}/>;
                            })
                        }
                    </div>
                );
            }
        });
        return(render)
    }

    const ItemLink = ({item}) => {
        return (
            <NavItem className="sub-nav-item">
                <NavLink
                    className="item-link"
                    to={item.path}
                    onClick={closeMenu}
                >
                    {item.title}
                    <FontAwesomeIcon icon="chevron-right" />
                </NavLink>
            </NavItem>
        )
    }

    if(!isLargeScreen && !leftNavVisible){
        return null;
    }

    if (menuPending){
        return (
        <Col className="left-nav-wrapper h-100" lg="3">
            <Container className="left-nav h-100">
                <div className="d-flex py-5 justify-content-center">
                        <Spinner style={{ width: '3rem', height: '3rem' }}/>
                </div>
            </Container>
        </Col>
        )
    }

    return (
        <Col className="left-nav-wrapper h-100" lg="3">
            <Container className="left-nav h-100">
                <Row className="left-nav-header">
                    <Col>
                    {
                        menuLevel === 0 && !isLargeScreen && !userIsSearching &&
                        <Button className="icon-btn close-btn" onClick={toggle}>
                            <FontAwesomeIcon icon="times" size="lg" />
                        </Button> 
                    }{
                        (menuLevel > 0 || userIsSearching) &&
                        <Button className="icon-btn back-btn" onClick={menuBack}>
                            <FontAwesomeIcon icon="chevron-left" size="lg" />
                        </Button>
                    }{
                        (!userIsSearching && menuLevel === 0) &&
                        <NavLink to="/" onClick={closeMenu}>
                            <img src={logo} alt="Communicate Comfort Logo" />
                        </NavLink>
                    }{
                        (!userIsSearching && menuLevel > 0) &&
                        <div className="searchHeader text-center mt-3 mb-5">
                            <b>{menuTree[parentIndex].title}</b>
                        </div>
                    }
                    {
                        (userIsSearching) &&
                        <div className="searchHeader text-center my-3">
                            <b>Search</b>
                        </div>
                    }
                    </Col>
                </Row>
                <Row className="search-container">
                    <Col>
                        {
                            (menuLevel === 0) && 
                            <Search
                                value={searchValue}
                                onSearchStart={handleSearchStart} 
                                onSearchChange={handleSearchChange}
                            />
                        }
                            
                    </Col>
                </Row>
                {
                    userIsSearching ? 
                    <Row className="search-results-container">
                        <Col>
                        {
                            searchPending ?
                            <div className="mt-3 d-flex flex-column align-items-center justify-content-center">
                                <div className="pending-text">
                                    Searching...
                                </div>
                                <Spinner style={{ width: '4.5rem', height: '4.5rem', color: '#855173' }} />
                            </div> :
                            !!searchResults.length ? 
                            <Nav navbar>
                                <NavItem className="search-result-item">
                                    <span className="item-name">Search Results</span>
                                    {renderedSearchResults}
                                </NavItem>    
                            </Nav> :
                            !!searchedTerm ? 
                            <div>No results found</div> :
                            <ul className="search-suggestions">
                                <span className="item-name">Recent Searches</span>
                                {
                                    !!recentSearches.length ? 
                                    searchSuggestions :
                                    <span>No recent searches</span>
                                }
                            </ul>
                        }
                        </Col>
                    </Row>
                    :
                    <Row className="nav-container">
                        <Col>
                            <Nav navbar>
                                {
                                    ( menuLevel !== 0) &&
                                        <li className="root-nav-item nav-item home-nav"><button className="transparent-btn btn btn-secondary"><a href="/"> <FontAwesomeIcon className="nav-icon home-icon" icon="home" size="lg" /> HOME</a></button></li>
                                }
                                { menuLevel === 0 ? rootMenu() : subMenu() }
                                    <div className="extra-links">
                                        <li className="root-nav-item nav-item"><button className="transparent-btn btn btn-secondary"><a href="https://www.communicatecomfort.com/nursing-faculty-resources">TEACHING RESOURCES</a></button></li>
                                        <li className="root-nav-item nav-item"><button className="transparent-btn btn btn-secondary"><a href="https://www.communicatecomfort.com/comfort-communication-app">ABOUT THE APP</a></button></li>
                                    </div>
                            </Nav>
                        </Col>
                    </Row>
                }
            </Container>
        </Col>
    );
}