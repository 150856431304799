import { compose, combineReducers, createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga';

import menuReducer from './reducers/menu';
import contentReducer from './reducers/content';
import searchReducer from './reducers/search';
import userReducer from './reducers/user';

import rootSaga from './sagas';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['userReducer'],
};

const sagaMiddleware = createSagaMiddleware();

export const rootReducer = combineReducers({
   menuReducer,
   contentReducer,
   searchReducer,
   userReducer
});


const persistedReducer = persistReducer(persistConfig, rootReducer)
  
const composeEnhancers = (process.env.NODE_ENV === 'development' ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
  
const enhancers = composeEnhancers(applyMiddleware(sagaMiddleware));

export const store = createStore(persistedReducer, enhancers);
export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
