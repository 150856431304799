export const SUBMIT_USER_FORM = 'USER/SUBMIT_USER_FORM';
export const SUBMIT_USER_FORM_SUCCESS = 'USER/SUBMIT_USER_FORM_SUCCESS';
export const SUBMIT_USER_FORM_ERRORS = 'USER/SUBMIT_USER_FORM_ERRORS';

export const submitUserForm = (params) => {
    return {
      type: SUBMIT_USER_FORM,
      params,
    };
};
