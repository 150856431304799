import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Button, Progress} from 'reactstrap';
import DefinitionCard from './cards/DefinitionCard';
import ImageCard from './cards/ImageCard';
import Header from '../components/Header';
import ResponseCard from './cards/ResponseCard';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import * as _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './CardDeck.scss';


function CardDeck({specificCard}) {
    const deckTitle = useSelector((s) => s.contentReducer.contentData.post_object.post_title);
    const [currentCardNumber, setCurrentCardNumber] = useState(0);
    const cards = useSelector((s) => s.contentReducer.contentData.post_content.card_deck_cards);
    const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });
    const renderedCards = _.map(cards, (card, idx) => {
        const cardType = card.card_type;
        const cardClasses = "card hc-card w-95 mx-auto h-100 d-flex flex-column justify-content-center"
        const cardProps = {
            key: idx,
            active: currentCardNumber === idx,
            card,
            cardClasses,
            deckTitle: deckTitle
        }
        switch(cardType){
            case "definition":
                return <DefinitionCard {...cardProps} />;
            case "image":
                return <ImageCard {...cardProps} />;
            case "response":
                return <ResponseCard {...cardProps} />
            default:
        }
    });

    const handleOnChange = (slide) => {
        setCurrentCardNumber(slide);
        if(slide === 0){
            setCurrentCardNumber(0)
        }
        if(slide > cards.length){
            setCurrentCardNumber(cards.length)
        }
    }
  
    useEffect(() => {
        if(specificCard){
            const cardIndex = _.findIndex(cards, ['card_machine_name', specificCard]);
            cardIndex > 0 && setCurrentCardNumber(cardIndex);
        }
    }, [specificCard, cards])

    return (
        <div className="h-100 d-flex flex-column deck-column">
            <Header sectionTitle={deckTitle}/>
            <div className="progress-container mx-auto d-flex align-items-center justify-content-between">
                <Progress
                    barStyle={{backgroundColor: '#C96939'}}
                    value={currentCardNumber + 1}
                    max={cards.length}
                />   
                <span className="card-counter">Card<br/><b>{`${currentCardNumber + 1}/${cards.length}`}</b></span>
            </div>
            <Carousel
                className="hc-carousel h-75"
                centerMode
                centerSlidePercentage={85}
                useKeyboardArrows={true}
                showStatus={false}
                showIndicators={false}
                showArrows={true}
                showThumbs={false}
                selectedItem={currentCardNumber}
                onClickItem={handleOnChange}
                onChange={handleOnChange} 
                swipeScrollTolerance={50}
            >

                {/* <div className="empty-slide d-none"></div> */}
                {renderedCards}
                {/* <div className="empty-slide d-none"></div> */}
            </Carousel>
            {
                isLargeScreen &&
                <div className="my-5 deck-controls d-flex justify-content-center">
                    <div className="button-wrapper">
                        <Button className="mx-5 icon-btn icon-white" onClick={() => setCurrentCardNumber(currentCardNumber - 1)}>
                            <FontAwesomeIcon icon="arrow-left" size="lg" />
                        </Button>
                        <Button className="mx-5 icon-btn icon-white" onClick={() => setCurrentCardNumber(currentCardNumber + 1)}>
                            <FontAwesomeIcon icon="arrow-right" size="lg" />
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
}

export default CardDeck;
