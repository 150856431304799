import * as Actions from '../actions/menu';

export const initialState = {
    menuPending: false,
    menuItems: [],
    menuErrors: null,
    menuLevel: 0,
    menuParent: null,
    leftNavVisible: false,
};

const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_MENU:
            return {
            ...state,
            menuPending: true,
        };
        case Actions.GET_MENU_SUCCESS:
            return {
            ...state,
            menuPending: false,
            menuItems: action.response.data,
        };
        case Actions.GET_MENU_ERRORS:
            return {
            ...state,
            menuPending: false,
            menuErrors: action.error,
        };
        case Actions.SET_MENU_LEVEL:
            return {
                ...state,
                menuLevel: action.params.menuLevel,
                menuParent: action.params.parentId,
            };
        case Actions.TOGGLE_LEFT_NAV:
            return {
                ...state,
                leftNavVisible: !state.leftNavVisible,
            };
      default:
        return state;
    }
};

export default menuReducer;
