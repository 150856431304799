import React from 'react';
import './Card.scss';
export default function DefinitionCard({card, cardClasses}){
    if (card.card_title.includes('Card')) {
        return(
            <div className={"definition " + cardClasses}>
                <p>{card.card_description}</p>
            </div>
        )
    }else {
        return (
            <div className={"definition " + cardClasses}>
                <h4 className="card-title">{card.card_title}</h4>
                <p>{card.card_description}</p>
            </div>
        )
    }
}