import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContent } from '../../redux/actions/content';
import { useParams } from "react-router-dom";
import CardDeck from '../../components/CardDeck';
import Table from '../../components/Table';
import { Spinner } from 'reactstrap';
import * as _ from 'lodash';

import './Content.scss';

function Content() {

    const dispatch = useDispatch();
    const params = useParams();
    const item = _.get(params, 'item', null);

    useEffect(() => {
        dispatch(getContent(params));
    }, [dispatch, params])

    const pending = useSelector((s) => s.contentReducer.contentPending);
    const contentType = useSelector((s) => s.contentReducer.contentData.post_type);

    return (
        <div className="content h-100">
            { 
                pending ? 
                <div className="d-flex justify-content-center">
                    <Spinner style={{ width: '3rem', height: '3rem' }} color="light"/>
                </div> :
                contentType === 'hc_card_deck' ? 
                <CardDeck specificCard={item}/> :
                contentType === 'hc_table' ? 
                <Table />:
                <p>Sorry, we couldn't find what you were looking for.</p>
            }
        </div>
    );
}

export default Content;
