import { all, call, spawn } from 'redux-saga/effects';

import { default as menuSagas } from './sagas/menu';
import { default as contentSagas } from './sagas/content';
import { default as searchSagas } from './sagas/search';
import { default as userSagas } from './sagas/user';

function* rootSaga () {
    const sagas = [
      contentSagas,
      menuSagas,
      searchSagas,
      userSagas
    ];
  
    yield all(sagas.map((saga) => 
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log('Root Saga Error: ', e);
          }
        }
      }),
    ));
  }
  
  export default rootSaga;