import React, { useState } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import * as _ from 'lodash';
import './Card.scss';
export default function ImageCard({card, cardClasses}){


    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const [modalCaption, setModalCaption] = useState('');

    const toggle = () => {
        setModalOpen(!modalOpen)
    } ;

    const populateModal = ({image, caption}) => {
        setModalImage(image);
        setModalCaption(caption);
        toggle();
    }

    const imageCount = _.map(_.get(card, 'card_images', [])).length;
    console.log(imageCount);

    const images = _.map(_.get(card, 'card_images', []), (image, idx) => {
        if (imageCount == 1) {
            return (
                <Col className="my-1" key={idx} xs="12">
                    <Button className="transparent-btn single-img-btn" onClick={() => populateModal(image)}>
                        <img className="single-img" src={image.image} alt={image.caption} />
                        <span className="single-img-caption">{image.caption}</span>
                    </Button>
                </Col>)
        }else {
            return (
                <Col className="my-1" key={idx} xs="6">
                    <Button className="transparent-btn" onClick={() => populateModal(image)}>
                        <img src={image.image} alt={image.caption} />
                    </Button>
                </Col>)
        }
    });

    return(
        <div className={"image " + cardClasses}>
            <Modal contentClassName="image-card-modal" isOpen={modalOpen} toggle={toggle} centered>
                <ModalBody>
                    <img className="modal-image" src={modalImage} alt={modalCaption} />
                </ModalBody>
                <ModalFooter>
                    <span>{modalCaption}</span>
                </ModalFooter>
            </Modal>
            <Container>
                <Row className="text-center">
                    <p className="w-100">{card.card_description}</p>
                </Row>
                <Row>{images}</Row>
            </Container>
        </div>
    )
}