import React, { useEffect, useRef, useCallback } from 'react';
import { Input } from 'reactstrap';

import './Search.scss';

export default function Search({onSearchStart, onSearchChange, value}){
    
    const searchRef = useRef();

    useEffect(() => {
        document.addEventListener('click', useInsideClickListener);

        return () => {
        document.removeEventListener('click', useInsideClickListener);
        };
    });

    const useInsideClickListener = useCallback((event) => {
        if (searchRef.current && searchRef.current.contains(event.target)) {
            onSearchStart();
        }
    }, [searchRef, onSearchStart]);

    return(
        <div ref={searchRef}>
            <Input 
                type="text"
                name="search"
                id="searchInput"
                className="search-input left-search"
                autoComplete="off"
                value={value}
                onChange={onSearchChange}
                ref={searchRef}
                placeholder="Search"             
            />         
        </div>
    )
}