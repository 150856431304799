export const GET_CONTENT = 'CONTENT/GET_CONTENT';
export const GET_CONTENT_SUCCESS = 'CONTENT/GET_CONTENT_SUCCESS';
export const GET_CONTENT_ERRORS = 'CONTENT/GET_CONTENT_ERRORS';

export const getContent = (params) => {
    return {
      type: GET_CONTENT,
      params,
    };
};
