import * as Actions from '../actions/search';

export const initialState = {
    searchPending: false,
    searchTerm: '',
    searchResults: [],
    searchErrors: null,
};

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_SEARCH:
            return {
            ...state,
            searchResults: [],
            searchPending: true,
            searchTerm: action.searchTerm,
        };
        case Actions.GET_SEARCH_SUCCESS:
            return {
            ...state,
            searchPending: false,
            searchResults: action.response.data,
        };
        case Actions.GET_SEARCH_ERRORS:
            return {
            ...state,
            searchPending: false,
            searchErrirs: action.error,
        };
        case Actions.CLEAR_SEARCH:
            return initialState;
      default:
        return state;
    }
};

export default searchReducer;
